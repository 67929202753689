import React, { useEffect, useState, useRef } from 'react';
import { LazyLoadImage } from "react-lazy-load-image-component";

import { typewriterText } from 'Utils.jsx';

import IMG_2661 from 'assets/res/img/IMG_2661.JPG';

import FadeInDiv from 'components/FadeInDiv.jsx';
import FadeInSection from 'components/FadeInSection.jsx';

import Styles from './Home.module.css';

const TITLE_TEXT = "Zachary Miller";
const TITLE_TEXT_2 = "";
const CURSOR_CHARACTER = "\u275A"; // ❚

const Home = () => {
    const titleText = useRef();
    const cursor = useRef();

    const section = useRef();

    const [count, setCount] = useState(0);

    useEffect(() => {
        if(count === 0) document.title = "zmshowroom";

        const onPageLoad = () => {
            let completed = typewriterText(count, setCount, titleText, cursor, TITLE_TEXT, TITLE_TEXT_2, CURSOR_CHARACTER);
            
            if(completed === true) {
                let images = section.current.querySelectorAll("." + CSS.escape(Styles["image-fade-in"]));
            
                images.forEach(image => {
                    image.classList.remove(Styles["image-fade-in"]);
                });
            }
        }

        if(document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);

            return () => window.removeEventListener('load', onPageLoad);
        }
    }, [count]);

    return (
        <>
            <section ref={section} id={Styles["home"]} className="standard">
                <LazyLoadImage src="https://images.unsplash.com/photo-1473448912268-2022ce9509d8?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb"
                    style={{transform: "scaleX(1)"}}
                    id={Styles["home-image"]}
                    className={`no-select ${Styles["image-fade-in"]}`}
                    width="100%" height="100%"
                    alt=""
                />
                <div id={Styles["home-image-gradient"]} className={`no-select ${Styles["image-fade-in"]}`}></div>
                <h1 id={Styles["home-title"]} className="standard">
                    <span ref={titleText}></span>
                    <span ref={cursor} className="no-select">{CURSOR_CHARACTER}</span>
                </h1>
            </section>
            <section id={Styles["home-about"]} className="standard">
                <FadeInDiv id={Styles["home-about-profile-image-container"]}>
                    <LazyLoadImage src={IMG_2661}
                        id={Styles["home-about-profile-image"]}
                        className={`no-select`}
                        alt=""
                    />
                </FadeInDiv>
                <div id={Styles["home-about-profile-blurb"]}>
                    <FadeInDiv>
                        <h1 id={Styles["home-about-profile-title"]}>About Me</h1>
                    </FadeInDiv>
                    <FadeInDiv>
                        <p class={Styles["home-about-profile-paragraph"]}>
                            I started programming websites when I was eight years old after watching a YouTube video on web development. 
                            I became fascinated with the idea of making a website and watched the video targeted at adults seven to 10 times soaking up every bit of knowledge. 
                            Then I got on my computer and started making a website from scratch.
                        </p>
                    </FadeInDiv>
                    <FadeInDiv>
                        <p class={Styles["home-about-profile-paragraph"]}>
                            Eight years later, I am an aspiring software developer, with proven experience in web and game development. 
                            I am currently a student at Edison High School in Alexandria, Virginia and am enrolled in the global STEM program. 
                            I got involved in the Technology Students Association in eight grade and competed in four national events. 
                            This year, I will be competing in game board and web design. 
                            I also participated in the Future Business Leaders of America Regional competition, taking a second-place finish with my team in web design. 
                        </p>
                    </FadeInDiv>
                </div>
            </section>
            <section id={Styles["home-my-expertise"]} className="standard">
                <FadeInSection id={Styles["home-expertise-title-section"]}>
                    <h1 id={Styles["home-expertise-title"]}>My Expertise</h1>
                </FadeInSection>
                <FadeInSection id={Styles["home-expertise-grid-section"]}>
                    <div id={Styles["home-expertise-grid"]}>
                        <div className={`${Styles["home-expertise-grid-item"]} ${Styles["home-expertise-outer-l-grid-item"]}`}>

                        </div>
                        <div className={Styles["home-expertise-grid-item"]}>

                        </div>
                        <div className={`${Styles["home-expertise-grid-item"]} ${Styles["home-expertise-outer-r-grid-item"]}`}>

                        </div>
                    </div>
                </FadeInSection>
            </section>
        </>
    );
}

export default Home;