import React, { useEffect, useState, useRef } from 'react';

import { typewriterText } from 'Utils.jsx';

import Styles from './Portfolio.module.css';

import Matrix from 'components/Matrix';

const TITLE_TEXT = "cd C:/Portfolio";
const TITLE_TEXT_2 = "";
const CURSOR_CHARACTER = "\u275A"; // ❚

const Portfolio = () => {
    const titleText = useRef();
    const cursor = useRef();

    const section = useRef();

    const [count, setCount] = useState(0);

    useEffect(() => {
        if(count === 0) document.title = "zmshowroom | Portfolio";

        const onPageLoad = () => {
            let completed = typewriterText(count, setCount, titleText, cursor, TITLE_TEXT, TITLE_TEXT_2, CURSOR_CHARACTER);
        
            if(completed === true) {
                let matrix = section.current.querySelector("." + CSS.escape(Styles["matrix-fade-in"]));
            
                if(matrix) {
                    matrix.classList.remove(Styles["matrix-fade-in"]);
                }
            }
        }

        if(document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);

            return () => window.removeEventListener('load', onPageLoad);
        }
    }, [count]);

    return (
        <section ref={section} id={Styles["portfolio"]} className="standard">
            <Matrix id={Styles["matrix-canvas"]} className={`no-select ${Styles["matrix-fade-in"]}`} fps={15} size={10} />
            <h1 id={Styles["portfolio-title"]} className="standard">
                <span ref={titleText}></span>
                <span ref={cursor} className="no-select">{CURSOR_CHARACTER}</span>
            </h1>
        </section>
    );
}

export default Portfolio;