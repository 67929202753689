import React, { useState, useEffect, useRef } from 'react';
import { useLocation, Link } from 'react-router-dom';
import axios from 'axios';

import Styles from './DirectoryMenu.module.css';

import FadeInSection from 'components/FadeInSection.jsx';

import { ReactComponent as SearchIcon } from 'assets/res/svg/SearchIcon_React.svg';

const PROJECTS_QUERY = `
{
    projects {
        name
        images {
            url
        }
        imageAlts
        description
        date
        url
    }
}
`;

const formatDate = (date) => {
    let splitDate = date.split("-");
    
    return `${splitDate[1].replace(/^0+/, '')}/${splitDate[2].replace(/^0+/, '')}/${splitDate[0].replace(/^0+/, '')}`;
}

const DirectoryMenu = () => {
    const location = useLocation();
    const search = new URLSearchParams(location.search);

    const menu = useRef();
    const entryModal = useRef();
    const slider = useRef();
    const back = useRef();

    const closeButton = useRef();
    const xButton = useRef();

    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState(data);
    const [selectedProject, setSelectedProject] = useState(filteredData[search.get("project") || 0]);

    const handleChange = e => {
        let input = e.target.value.toLowerCase();

        setFilteredData(data.projects.filter(project => {
            let includesName = project.name.toLowerCase().includes(input);
            let includesDate = project.date.toLowerCase().includes(input);

            return includesName || includesDate;
        }));
    }

    const closeModal = (e) => {
        if(!entryModal.current) return;

        let active = document.querySelector("." + CSS.escape(Styles["entry-modal-active"]));
        if(!active) return;

        let isClickInside = entryModal.current.firstChild.contains(e.target) && (!closeButton.current.contains(e.target) && !xButton.current.contains(e.target));
        if(menu.current.contains(e.target)) isClickInside = true;

        if(!isClickInside) {
            entryModal.current.classList.remove(Styles["entry-modal-active"]);

            document.removeEventListener('click', closeModal);
            
            setTimeout(() => {
                slider.current.scrollLeft = 0;
                back.current.click();
            }, 250);
        }
    }

    const openModal = (project) => {
        if(project) setSelectedProject(project);

        setTimeout(() => {
            slider.current.scrollLeft = 0;
            entryModal.current.classList.add(Styles["entry-modal-active"]);
            document.addEventListener('click', closeModal, false);

            if(slider.current.querySelector("img") == null) {
                slider.current.parentNode.style.display = "none";
            } else {
                slider.current.parentNode.style.display = "block";
            }
        }, 0);
    }

    useEffect(() => {
        if(window.location.pathname === "/directory") {
            window.history.replaceState('', '', '/directory/');
            window.location.pathname = "/directory/";

            return;
        }

        const fetchData = async () => {
            axios.post(
                process.env.REACT_APP_GRAPHQL_API, {
                    query: PROJECTS_QUERY
                }
            ).then(result => {
                setData(result.data.data);
                setFilteredData(result.data.data.projects);
                setSelectedProject(result.data.data.projects[search.get("project") || 0]);
            }).catch(err => {
                console.warn(err);
            });
        }

        fetchData();

        if(search.has("project")) {
            document.addEventListener('click', closeModal, false)
        } else {
            document.removeEventListener('click', closeModal);
        }
    }, []);

    return (
        <>
            <FadeInSection id={Styles["directory-menu"]} className="standard">
                <div ref={menu} className={Styles["list-projects"]}>
                    <div className={Styles["list-header"]}>
                        <div className={Styles["list-search-bar-container"]}>
                            <input type="search" onChange={handleChange} className={Styles["list-search-bar"]} />
                            <div className={Styles["list-search-bar-icon"]}>
                                <SearchIcon />
                            </div>
                        </div>
                    </div>
                    <div className={Styles["list-entries"]}>
                        <table>
                            <tbody>
                                {filteredData.map((project, i) => {
                                    return (
                                        <tr className={Styles["list-entry"]}>
                                            <td className={Styles["list-entry-name"]}>
                                                <Link to={`/directory/?project=${i}`} onClick={() => {
                                                    openModal(project);
                                                }}>{project.name}</Link>
                                            </td>
                                            <td className={Styles["list-entry-date"]}>
                                                <Link to={`/directory/?project=${i}`} onClick={() => {
                                                    openModal(project);
                                                }}>{formatDate(project.date)}</Link>
                                            </td>
                                        </tr> 
                                    );
                                })}
                            </tbody>
                        </table>
                    </div>
                    <div className={Styles["list-footer"]}></div>
                </div>
                <Link ref={back} to="/directory/"></Link>
            </FadeInSection>
            <div ref={entryModal} className={`${Styles["entry-modal-outer"]} ${search.has("project") ? Styles["entry-modal-active"] : ''}`}>
                <div className={Styles["entry-modal"]}>
                    <i ref={xButton} className={`fa-solid fa-x ${Styles["close"]}`} onClick={closeModal} ></i>
                    {selectedProject ? (
                    <div className={Styles["entry-modal-inner"]}>
                        <h1 className={Styles["entry-modal-title"]}>{selectedProject.name} - {formatDate(selectedProject.date)}</h1>
                        <div className={Styles["slider-wrapper"]}>
                            <div ref={slider} className={Styles["slider"]}>
                                {selectedProject.images.map((image, i) => {
                                    return <img id={`slide-${i}`} src={image.url} alt={selectedProject.imageAlts[i]} />
                                })}
                            </div>
                            <div className={Styles["slider-nav"]}>
                                {selectedProject.images.map((_, i) => {
                                    return <Link to="javascript:void(0);" onClick={() => {
                                        let slide = document.getElementById(`slide-${i}`);
                                        slider.current.scrollLeft = slide.offsetLeft;
                                    }}></Link>
                                })}
                            </div>
                        </div>
                        <p className={Styles["entry-modal-description"]}>&emsp;{selectedProject.description}</p>
                        <form action={selectedProject.url}>
                            <div className={Styles["button"]}>
                                <button ref={closeButton} type="button" onClick={closeModal} className={Styles["cancel"]}>Close</button>
                                <button type="button" onClick={() => {window.location.href = selectedProject.url}} className={Styles["visit"]}>Visit</button>
                            </div>
                        </form>
                    </div>
                    ) : ""}
                </div>
            </div>
        </>
    );
}

export default DirectoryMenu;