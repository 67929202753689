import React from 'react';

import Styles from './Footer.module.css';

const Footer = () => {
    return (
        <section id="footer" className={Styles["footer"]}>

        </section>
    );
}

export default Footer;