import React, { useEffect, useState, useRef } from 'react';

import { typewriterText } from 'Utils.jsx';

import Styles from './Contact.module.css';

const TITLE_TEXT = "cd C:/Contact";
const TITLE_TEXT_2 = "";
const CURSOR_CHARACTER = "\u275A"; // ❚

const Contact = () => {
    const titleText = useRef();
    const cursor = useRef();

    const [count, setCount] = useState(0);

    useEffect(() => {
        if(count === 0) document.title = "zmshowroom | Contact";

        const onPageLoad = () => {
            typewriterText(count, setCount, titleText, cursor, TITLE_TEXT, TITLE_TEXT_2, CURSOR_CHARACTER);
        }

        if(document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);

            return () => window.removeEventListener('load', onPageLoad);
        }
    }, [count]);

    return (
        <section id={Styles["contact"]} className="standard">
            <h1 id={Styles["contact-title"]} className="standard">
                <span ref={titleText}></span>
                <span ref={cursor} className="no-select">{CURSOR_CHARACTER}</span>
            </h1>
        </section>
    );
}

export default Contact;