import React, { useEffect, useRef } from 'react';

const DEFAULT_MATRIX_CHARACTERS = 'アァカサタナハマヤャラワガザダバパイィキシチニヒミリヰギジヂビピウゥクスツヌフムユュルグズブヅプエェケセテネヘメレヱゲゼデベペオォコソトノホモヨョロヲゴゾドボポヴッン0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
const DEFAULT_FPS = 15;
const DEFAULT_FONT_SIZE = 25;

class Symbol {
    constructor(x, y, fontSize, canvasHeight, characters) {
        this.characters = characters;
        this.x = x;
        this.y = y;
        this.fontSize = fontSize;
        this.text = '';
        this.canvasHeight = canvasHeight;
    }

    draw(ctx) {
        this.text = this.characters.charAt(Math.floor(Math.random() * this.characters.length));
        
        ctx.fillText(this.text, this.x * this.fontSize, this.y * this.fontSize);

        if(this.y * this.fontSize > this.canvasHeight && Math.random() > 0.98) {
            this.y = 0;
        } else {
            this.y += 1;
        }
    }
} 

class Effect {
    constructor(canvasWidth, canvasHeight, fontSize, characters) {
        this.canvasWidth = canvasWidth;
        this.canvasHeight = canvasHeight;
        this.fontSize = fontSize;
        this.columns = this.canvasWidth / this.fontSize;
        this.characters = characters;
        this.symbols = [];

        this.#initialize();
    }

    #initialize() {
        for(let i = 0; i < this.columns; i++) {
            this.symbols[i] = new Symbol(i, 0 - Math.random() * this.canvasHeight / 2, this.fontSize, this.canvasHeight, this.characters);
        }
    }

    resize(width, height) {
        this.canvasWidth = width;
        this.canvasHeight = height;
        this.columns = this.canvasWidth / this.fontSize;
        this.symbols = [];

        this.#initialize();
    }
}

const Matrix = (props) => {
    const { ...canvasProps } = props;

    const canvasRef = useRef();

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        canvas.width = canvas.parentNode.offsetWidth;
        canvas.height = canvas.parentNode.offsetHeight;

        const frameRate = props.fps || DEFAULT_FPS;
        const nextFrame = 1000 / frameRate;
        const fontSize = props.size || DEFAULT_FONT_SIZE;
        const matrixCharacters = props.characters || DEFAULT_MATRIX_CHARACTERS;
        let textFill = props.fill ? props.fill(canvas, ctx) : '#0aff0a';

        const effect = new Effect(canvas.width, canvas.height, fontSize, matrixCharacters);

        let lastTime = 0;
        let timer = 0;
        let animationFrameId;

        const animate = (timeStamp) => {
            const dt = timeStamp - lastTime;
            lastTime = timeStamp;

            if(timer > nextFrame) {
                ctx.fillStyle = 'rgba(0, 0, 0, 0.05)';
                ctx.textAlign = 'center';
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                ctx.fillStyle = textFill;
                ctx.font = effect.fontSize + 'px monospace';
                effect.symbols.forEach(symbol => symbol.draw(ctx));

                timer = 0;
            } else {
                timer += dt;
            }

            animationFrameId = window.requestAnimationFrame(animate);
        }

        animate(0);

        const resize = () => {
            const newWidth = canvas.parentNode.offsetWidth;
            const newHeight = canvas.parentNode.offsetHeight

            if(newWidth !== canvas.width || newHeight !== canvas.height) {
                canvas.width = canvas.parentNode.offsetWidth;
                canvas.height = canvas.parentNode.offsetHeight;

                textFill = props.fill ? props.fill(canvas, ctx) : '#0aff0a';

                effect.resize(canvas.width, canvas.height);
            }
        }

        window.addEventListener('resize', resize);

        return () => {
            window.cancelAnimationFrame(animationFrameId);
            window.removeEventListener('resize', resize);
        }
    }, []);

    return (<canvas ref={canvasRef} {...canvasProps} />);
}

export default Matrix