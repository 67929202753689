import React from 'react';

import Navigation from './Navigation.jsx';

import Styles from './Header.module.css';

const Header = () => {
    return (
        <section id="header">
            <Navigation />
            <div id="top" class={Styles["top"]}></div>
        </section>
    );
}

export default Header;