export function randomInt(min, max) {
    return Math.floor(Math.random() * (min - max + 1) + min);
}

export function typewriterText(count, setCount, titleText, cursor, TITLE_TEXT, TITLE_TEXT_2, CURSOR_CHARACTER) {
    if(count === 0) {
        titleText.current.textContent = "";

        setTimeout(() => {
            setCount(count + 1);
        }, 500);
    } else if(count < TITLE_TEXT.length && TITLE_TEXT.length !== 0) {
        titleText.current.textContent = TITLE_TEXT.slice(0, count + 1);

        let [min, max] = [100, 150];
        
        if(TITLE_TEXT[count] === " ") {
            let oldMin = min;
            min *= 3;

            max += min - oldMin;
        }

        setTimeout(() => {
            setCount(count + 1);
        }, randomInt(min, max));
    } else if(count === TITLE_TEXT.length && TITLE_TEXT.length !== 0 && TITLE_TEXT_2.length !== 0) {
        setTimeout(() => {
            setCount(count + 1);
        }, 500);
    } else if(count > TITLE_TEXT.length && count < TITLE_TEXT.length * 2 && TITLE_TEXT.length !== 0 && TITLE_TEXT_2.length !== 0) {
        titleText.current.textContent = TITLE_TEXT.slice(0, TITLE_TEXT.length * 2 - count - 1);

        let [min, max] = [100, 150];

        setTimeout(() => {
            setCount(count + 1);
        }, randomInt(min, max));
    } else if(count === TITLE_TEXT.length * 2 && TITLE_TEXT.length !== 0 && TITLE_TEXT_2.length !== 0) {
        setTimeout(() => {
            setCount(count + 1);
        }, 500);
    } else if(count > TITLE_TEXT.length * 2 && count < TITLE_TEXT.length * 2 + TITLE_TEXT_2.length + 1 && TITLE_TEXT_2.length !== 0) {
        titleText.current.textContent = TITLE_TEXT_2.slice(0, (TITLE_TEXT.length * 2 - count) * -1);

        let [min, max] = [100, 150];
        
        if(TITLE_TEXT[count] === " ") {
            let oldMin = min;
            min *= 3;

            max += min - oldMin;
        }

        setTimeout(() => {
            setCount(count + 1);
        }, randomInt(min, max));
    } else {
        setTimeout(() => {
            if(cursor.current) {
                cursor.current.classList.toggle("transparent");

                setCount(count + 1);
            }
        }, 700);

        return true;
    }

    return false;
}