import React, { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import Styles from './Navigation.module.css';

const Navigation = () => {
    const toggle = useRef();
    const menu = useRef();

    function closeSubmenu(e) {
        if(!menu.current) return;

        let isClickInside = menu.current.contains(e.target);
        let submenu = menu.current.querySelector("." + CSS.escape(Styles["submenu-active"]));

        if(!isClickInside && submenu) {
            submenu.classList.remove(Styles["submenu-active"]);
        }
    }

    function linkClicked() {
        toggle.current.querySelector("a").innerHTML = "<i class='fas fa-bars'></i>";
        menu.current.classList.remove(Styles["active"]);
    }

    function toggleMenu() {
        if(menu.current.classList.contains(Styles["active"])) {
            toggle.current.querySelector("a").innerHTML = "<i class='fas fa-bars'></i>";
        } else {
            toggle.current.querySelector("a").innerHTML = "<i class='fas fa-times'></i>";
        }

        menu.current.classList.toggle(Styles["active"]);
    }

    useEffect(() => {
        const items = document.querySelectorAll(`.${Styles["item"]}`);

        function toggleItem() {
            if(this.classList.contains(Styles["submenu-active"])) {
                this.classList.remove(Styles["submenu-active"])
            } else if(document.querySelector("." + CSS.escape(Styles["submenu-active"]))) {
                document.querySelector("." + CSS.escape(Styles["submenu-active"])).classList.remove(Styles["submenu-active"]);
                this.classList.add(Styles["submenu-active"]);
            } else {
                this.classList.add(Styles["submenu-active"]);
            }
        }

        for(let item of items) {
            if(item.querySelector(`.${Styles["submenu"]}`)) {
                item.addEventListener("click", toggleItem, false);
                item.addEventListener("keypress", toggleItem, false);
            }
        }

        document.addEventListener('click', closeSubmenu, false);
    }, []);

    return (
        <nav>
            <ul ref={menu} className={Styles["menu"]}>
                <li className={Styles["logo"]}>
                    <Link to="/" onClick={linkClicked}>
                        ZMSHOWROOM
                    </Link>
                </li>
                <li className={Styles["item"]}>
                    <Link to="/" onClick={linkClicked}>
                        Home
                    </Link>
                </li>
                <li className={Styles["item"]}>
                    <Link to="/portfolio/" onClick={linkClicked}>
                        Portfolio
                    </Link>
                </li>
                {/* Projects submenu
                <li className={`${Styles["item"]} ${Styles["has-submenu"]}`}>
                    <a tabIndex="0">Projects</a>
                    <ul className={Styles["submenu"]}>
                        <li className={Styles["subitem"]}>
                            <Link to="/portfolio/" onClick={linkClicked}>Portfolio</Link>
                        </li>
                        <li className={Styles["subitem"]}>
                            <Link to="/directory/" onClick={linkClicked}>Directory</Link>
                        </li>
                    </ul>
                </li>
                */}
                <li className={Styles["item"]}>
                    <Link to="/blog/" onClick={linkClicked}>Blog</Link>
                </li>
                <li className={Styles["item"]}>
                    <Link to="/about/" onClick={linkClicked}>About</Link>
                </li>
                <li className={Styles["item"]}>
                    <Link to="/contact/" onClick={linkClicked}>Contact</Link>
                </li>
                <li className={`${Styles["item"]} ${Styles["button"]}`}>
                    <Link to="#" onClick={linkClicked}>Log In</Link>
                </li>
                <li className={`${Styles["item"]} ${Styles["button"]} ${Styles["secondary"]}`}>
                    <Link to="#" onClick={linkClicked}>Sign Up</Link>
                </li>
                <li ref={toggle} className={Styles["toggle"]}>
                    <Link to="#" onClick={event => toggleMenu()}>
                        <i className="fas fa-bars"></i>
                    </Link>
                </li>
            </ul>
        </nav>
    );
}

export default Navigation;