import React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';

import ScrollToTop from './ScrollToTop.jsx';

import Home from './components/pages/Home/Home.jsx';
import Portfolio from './components/pages/Portfolio/Portfolio.jsx';
import Directory from './components/pages/Directory/Directory.jsx';
import Blog from './components/pages/Blog/Blog.jsx';
import About from './components/pages/About/About.jsx';
import Contact from './components/pages/Contact/Contact.jsx';

import Page404 from './components/pages/Page404/Page404.jsx';

export const AnimatedRoutes = () => {
    const location = useLocation();

    return (
        <Routes location={location} key={location.pathname}>
            <Route path={"/"} element={<ScrollToTop><Home /></ScrollToTop>} />
            <Route path={"/portfolio/"} element={<ScrollToTop><Portfolio /></ScrollToTop>} />
            <Route path={"/directory/"} element={<ScrollToTop><Directory /></ScrollToTop>} />
            <Route path={"/blog/"} element={<ScrollToTop><Blog /></ScrollToTop>} />
            <Route path={"/about/"} element={<ScrollToTop><About /></ScrollToTop>} />
            <Route path={"/contact/"} element={<ScrollToTop><Contact /></ScrollToTop>} />
            <Route path="*" element={<ScrollToTop><Page404 /></ScrollToTop>} />
        </Routes>
    );
}

export default AnimatedRoutes;