import React, { useState, useEffect, useRef } from 'react';

import Styles from './FadeInSection.module.css';

const FadeInDiv = (props) => {
  const domRef = useRef();
  
  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      // In your case there's only one element to observe:     
      if (entries[0].isIntersecting) {
        setTimeout(() => {
          // Not possible to set it back to false like this:
          setVisible(true);
          
          // No need to keep observing:
          observer.unobserve(domRef.current);
        }, 150);
      }
    });
    
    observer.observe(domRef.current);
    
    return () => observer.disconnect();
  }, []);

  return (<div ref={ domRef } id={props.id} className={ (props.className ? props.className : '') + ` ${Styles["fade-in-div"]}` + (isVisible ? ` ${Styles["is-visible"]}` : '' )}>{ props.children }</div>);
};

export default FadeInDiv;