import React, { useEffect, useState, useRef } from 'react';

import { typewriterText } from 'Utils.jsx';

import DirectoryMenu from './DirectoryMenu.jsx';

import Styles from './Directory.module.css';

import Matrix from 'components/Matrix';

const TITLE_TEXT = "cd C:/Directory";
const TITLE_TEXT_2 = "";
const CURSOR_CHARACTER = "\u275A"; // ❚

const Directory = () => {
    const titleText = useRef();
    const cursor = useRef();

    const section = useRef();

    const [count, setCount] = useState(0);

    useEffect(() => {
        if(count === 0) document.title = "zmshowroom | Directory";

        const onPageLoad = () => {
            const completed = typewriterText(count, setCount, titleText, cursor, TITLE_TEXT, TITLE_TEXT_2, CURSOR_CHARACTER);
        
            if(completed === true) {
                let matrix = section.current.querySelector("." + CSS.escape(Styles["matrix-fade-in"]));
            
                if(matrix) {
                    matrix.classList.remove(Styles["matrix-fade-in"]);
                }
            }
        }

        if(document.readyState === 'complete') {
            onPageLoad();
        } else {
            window.addEventListener('load', onPageLoad);

            return () => window.removeEventListener('load', onPageLoad);
        }
    }, [count]);

    return (
        <>
            <section ref={section} id={Styles["directory"]} className="standard">
                <Matrix id={Styles["matrix-canvas"]} className={`no-select ${Styles["matrix-fade-in"]}`} fps={15} size={10} fill={(canvas, ctx) => {
                    let gradient = ctx.createLinearGradient(0, 0, canvas.width, canvas.height);
                            
                    gradient.addColorStop(0, 'red');
                    gradient.addColorStop(0.2, 'yellow');
                    gradient.addColorStop(0.4, 'green');
                    gradient.addColorStop(0.6, 'cyan');
                    gradient.addColorStop(0.8, 'blue');
                    gradient.addColorStop(1, 'red');

                    return gradient;
                }} />
                <h1 id={Styles["directory-title"]} className="standard">
                    <span ref={titleText}></span>
                    <span ref={cursor} className="no-select">{CURSOR_CHARACTER}</span>
                </h1>
            </section>
            <DirectoryMenu />
        </>
    );
}

export default Directory;